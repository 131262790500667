import classNames from 'classnames/bind';
import { addMinutes } from 'date-fns';
import { useAppSelector } from '../../../store/hooks';
import { selectUserInformation } from '../../../store/reducers';
import { Headline } from '../../atoms';
import { Header } from '../../organisms';
import { Accordion } from '../../organisms/Accordion/Accordion';
import { BookFirstAppointment } from './StageElements/BookFirstAppointment';
import { BookFollowUpAppointment } from './StageElements/BookFollowUpAppointment';
import { FirstAppointmentBooked } from './StageElements/FirstAppointmentBooked';
import { FollowUpAppointmentBooked } from './StageElements/FollowUpAppointmentBooked';
import { FollowUpPrescription } from './StageElements/FollowUpPrescription';
import { MedicationInfo } from './StageElements/MedicationInfo';
import { PatientDeclined } from './StageElements/PatientDeclined';
import { PatientNotSuitable } from './StageElements/PatientNotSuitable';
import { PharmacyInfo } from './StageElements/PharmacyInfo';
import { PrescriptionInfo } from './StageElements/PrescriptionInfo';
import { Questionnaire } from './StageElements/Questionnaire';
import { StayTuned } from './StageElements/StayTuned';
import { WaitingForApproval } from './StageElements/WatingForApproval';
import style from './Dashboard.module.scss';

const cx = classNames.bind(style);

export function Dashboard(): JSX.Element {
  const userInformation = useAppSelector(selectUserInformation);

  // needed conditions:
  // 1. Questionnaire not finished
  // 2. Checking Questionnaire
  // 3. Book First Appointment
  // 3.1. First Appointment booked
  // 3.2. First Appointment took place -> awaiting Status from Doctor
  // 4. Book Follow Up Appointment
  // 4.1. Follow Up Appointment booked
  // 4.2. Follow Up Appointment took place -> awaiting Status from Doctor
  // 5. Request Follow Up Prescription (condition: at least 2 appointments took place)
  // ----
  // 6. Patient is declined / blocked

  // clean up appointments -> remove excused and cancelled
  const filteredPatientAppointments = userInformation?.appointment?.filter(
    (a) => String(a.carriedOut) !== '2' && String(a.carriedOut) !== '3'
  );

  const delayForStayTunedAfterAppointment = 10; // in minutes
  // True if latest appointment is more than 10 Minutes ago and no status is set by the doctor
  const showStayTuned =
    userInformation?.progress.isDataConfirm &&
    filteredPatientAppointments?.length > 0 &&
    filteredPatientAppointments[filteredPatientAppointments.length - 1].carriedOut === undefined &&
    new Date() >
      addMinutes(
        new Date(filteredPatientAppointments[filteredPatientAppointments.length - 1]?.endDate),
        delayForStayTunedAfterAppointment
      );
  const isPatientDeclined = userInformation?.isPatientDeclined && userInformation.isPatientDeclined === true;
  const isPatientNotSuitable =
    userInformation?.isPatientNotSuitable !== undefined && userInformation.isPatientNotSuitable === true;

  const accordionData = [
    {
      title: 'Mit welchen Kosten kann ich für eine Cannabis-Therapie rechnen?',
      content: `Da es sich bei der Therapie um eine privatärztliche Leistung handelt, sind wir dazu verpflichtet nach der GOÄ (Gebührenordnung für Ärzte) abzurechnen.
      Unser Preismodell finden Sie auf unserer Website.`,
    },
    {
      title: 'Woher weiß ich, dass mein Termin bestätigt ist?',
      content: `Sobald Sie den Termin gebucht haben, sehen Sie die Bestätigung in der App auf dem Dashboard. Sie erhalten außerdem eine Bestätigungsemail mit allen wichtigen Informationen.`,
    },
    {
      title: 'Wie sage ich einen Termin ab? ',
      content: `Spätestens 24h vor dem Termin müssen Sie sich per Telefon, E-Mail oder Chat an uns wenden, um einen Termin abzusagen damit auch keine Ausfallgebühren veranlasst werden.
      Im Falle einer Störung unmittelbar bei Ihrem Termin müssen Sie sich sofort per Telefon, E-Mail oder Chat an uns wenden. `,
    },
    {
      title: 'Mein Präparat ist in der Apotheke nicht mehr verfügbar, was nun?',
      content: `Ihre Apotheke setzt sich mit Ihnen in Verbindung oder mit Ihrem Arzt. Die Apotheke kann in Rücksprache mit dem Arzt das Rezept abändern ohne das ein neues Rezept ausgestellt werden muss. Sollte Ihre Apotheke nicht bereit sein, diese Rücksprache vorzunehmen, müssen Sie ein neues Rezept bestellen. Am besten prüfen Sie vor Ihrer Apothekenwahl ob Ihre Wunschapotheke diesen Service anbietet.`,
    },
    {
      title: 'Auf welchem Weg erhalte ich meine Rechnung?',
      content: `Ihre Rechnung wird Ihnen auf dem Postweg von der ärztlichen Verrechnungsstelle Büdingen zugeschickt. Mit Eingang der Rechnung wird die Zahlung fällig. Begleichen Sie den Rechnungsbetrag unter Angabe des Verwendungszwecks (Rechnungsnummer).`,
    },
  ];

  const medicationPharmacyInfoClasses = cx({ MedicationPharmacyInfo: true });
  const faqSectionClasses = cx({ FaqSection: true });
  const currentStepContainerClasses = cx({ CurrentStepContainer: true }, 'fullWidthSectionInContainer');

  return (
    <>
      <Header showBackButton={false} />
      <div>
        <div>
          {userInformation.id !== '' && (
            <div className={currentStepContainerClasses}>
              {/*
                Condition for 3.2: First Appointment took place -> awaiting Status from Doctor
                Condition for 4.2: Follow Up Appointment took place -> awaiting Status from Doctor
    */}
              {showStayTuned === true && <StayTuned filteredPatientAppointments={filteredPatientAppointments} />}
              {/*
                Condition for 6: Patient is declined / blocked
        */}
              {isPatientDeclined === true && <PatientDeclined />}
              {isPatientNotSuitable === true && <PatientNotSuitable />}

              {showStayTuned === false && isPatientDeclined === false && isPatientNotSuitable === false && (
                <>
                  {/*
                Condition for 1: Questionnaire not finished
        */}
                  {userInformation.progress.isDataConfirm === false && <Questionnaire />}
                  {/*
                Condition for 2: Checking Questionnaire
        */}
                  {userInformation.progress.isDataConfirm === true && userInformation.patientDataApproved !== true && (
                    <WaitingForApproval />
                  )}
                  {/*
                Condition for 3: Book First Appointment
        */}
                  {userInformation.progress.isDataConfirm === true &&
                    userInformation.patientDataApproved === true &&
                    (filteredPatientAppointments?.length === 0 || !filteredPatientAppointments) && (
                      <BookFirstAppointment />
                    )}
                  {/*
                Condition for 3.1: First Appointment booked
        */}
                  {userInformation.progress.isDataConfirm === true &&
                    userInformation.patientDataApproved === true &&
                    filteredPatientAppointments?.length === 1 &&
                    new Date() <
                      addMinutes(
                        new Date(filteredPatientAppointments[filteredPatientAppointments.length - 1]?.endDate),
                        delayForStayTunedAfterAppointment
                      ) && <FirstAppointmentBooked appointmentInformation={filteredPatientAppointments[0]} />}
                  {/*
                Condition for 4: Book Follow Up Appointment
        */}
                  {userInformation.progress.isDataConfirm === true &&
                    userInformation.patientDataApproved === true &&
                    filteredPatientAppointments?.length >= 1 &&
                    String(filteredPatientAppointments[filteredPatientAppointments.length - 1].carriedOut) === '1' && (
                      <BookFollowUpAppointment allAppointments={filteredPatientAppointments} />
                    )}
                  {/*
                  Condition for 4.1: Follow Up Appointment booked
          */}
                  {userInformation.progress.isDataConfirm === true &&
                    userInformation.patientDataApproved === true &&
                    filteredPatientAppointments?.length > 1 &&
                    new Date() <
                      addMinutes(
                        new Date(filteredPatientAppointments[filteredPatientAppointments.length - 1]?.endDate),
                        delayForStayTunedAfterAppointment
                      ) && (
                      <FollowUpAppointmentBooked
                        appointmentInformation={filteredPatientAppointments[filteredPatientAppointments.length - 1]}
                      />
                    )}
                  {/*
                Condition for 5: Request Follow Up Prescription (condition: at least 1 appointment took place)
        */}
                  {filteredPatientAppointments?.length >= 1 &&
                    String(filteredPatientAppointments[filteredPatientAppointments.length - 1].carriedOut) === '1' &&
                    userInformation.medication?.length > 0 && (
                      <FollowUpPrescription userInformation={userInformation} />
                    )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {userInformation.id !== '' && isPatientDeclined === false && isPatientNotSuitable === false && (
        <>
          <div className={medicationPharmacyInfoClasses}>
            {userInformation.medication?.length > 0 && <MedicationInfo medication={userInformation.medication} />}
            {(userInformation.patientPharmacyRelation || filteredPatientAppointments?.length === 1) && (
              <PharmacyInfo pharmacy={userInformation.patientPharmacyRelation} />
            )}
          </div>
          {userInformation.prescription && userInformation.prescription.length > 0 && (
            <div className={medicationPharmacyInfoClasses}>
              <PrescriptionInfo prescriptions={userInformation.prescription} />
            </div>
          )}
        </>
      )}
      <div className={faqSectionClasses}>
        <Headline level={4} center={true}>
          Häufige Fragen
        </Headline>
        <Accordion accordionData={accordionData} />
      </div>
    </>
  );
}
